import React, { useState, useRef, useEffect  } from 'react';
import dogMoney from './dogMoney.mp4';

import './App.css';
import Socials from './socials';
import healdog from './healdog.png';
import bonked from './bonked.png';
import healing from './healing.jpg';
import pimpDog from './pimpDog.jpg';
import DinoDoge from './DinoDoge.png';
import ufoDog from './ufoDog.jpg';
import Contract from './contract';
import { useSpring, animated } from 'react-spring';


function TradeHeal() {
  const [isOpen, setIsOpen] = useState(false);

  const openTradeUrl = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="trade-heal-container">
      <h3 onClick={() => setIsOpen(!isOpen)}>Trade $heal</h3>
      {isOpen && (
        <div className="trade-options-container">
          <button className= "usdcTrade" onClick={() => openTradeUrl("https://raydium.io/swap/?inputCurrency=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputCurrency=GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb&inputAmount=0&fixed=in")}>
            Trade heal/USDC
          </button>
          <button onClick={() => openTradeUrl("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb&inputAmount=0&fixed=in")}>
            Trade heal/SOL
          </button>
          <iframe 
            src="https://birdeye.so/token/GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb?chain=solana" 
            title="Trade Heal"
            className="trade-iframe"
            style={{ display: isOpen ? "block" : "none" }}
            // Add any additional attributes like width and height as needed
          />
        </div>
      )}
    </div>
  );
}

interface AnimatedLetterProps {
  letter: string;
}

function AnimatedLetter({ letter }: AnimatedLetterProps) {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  return (
    <animated.span
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: [x - window.innerWidth / 2, y - window.innerHeight / 2] })}
      onMouseLeave={() => set({ xy: [0, 0] })}
      style={{ 
        transform: props.xy.interpolate((x, y) => `translate3d(${x / 10}px, ${y / 10}px, 0)`) 
      }}
    >
      {letter}
    </animated.span>
  );
}


function App() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false); // State to track audio playback

  const toggleAudio = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause(); // If playing, pause the audio
        audioElement.loop = false; // Stop looping when paused
      } else {
        audioElement.play(); // If not playing, play the audio
        audioElement.loop = true; // Start looping when played
      }
      setIsPlaying(!isPlaying); // Toggle the isPlaying state
    }
  };

  useEffect(() => {
    // Play the audio initially but keep it paused
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.play().then(() => {
        audioElement.pause();
      }).catch(error => console.error('Error attempting to play audio:', error));
    }
  }, []);
  
  return (
    <div className="App">
      <audio ref={audioRef} src={dogMoney} hidden></audio>
      <header className="App-header">
      <div className="image-container">
        <img src={bonked} alt="Bonked" className="side-image"/>
        <img src={healing} alt="Healing" className="side-image"/>
        <img src={healdog} alt="Heal Dog" className="top-image"/>
        <img src={pimpDog} alt="Pimp Dog" className="side-image"/>
        <img src={ufoDog} alt="UFO Dog" className="side-image"/>
      </div>
      <div className="text-container">
        <h1 className="glitch" data-text="$heal">
          <AnimatedLetter letter="$"/>
          <AnimatedLetter letter="h"/>
          <AnimatedLetter letter="e"/>
          <AnimatedLetter letter="a"/>
          <AnimatedLetter letter="l"/>
        </h1>
        <h2>The only meme coin intended to heal your wallet</h2>
        <h3>Only Possible on Soylana</h3>
      </div>
      </header>
      <div className="green-container">
        <div className="about-section">
          <h3>About $heal</h3>
          <p>Got rugged? Bonked? Bamboozled? Someone took a dump on your positions?</p>
          <p>That's ok, here comes $heal, the best way to generate positive vibes to your wallet.</p>
          <p>We're just a memecoin, there's no utility, we're just here to heal your spirit.</p>

          <h3>Tap Dog, Feel Beats</h3>
          {isPlaying && (
            <button onClick={toggleAudio} style={{ marginTop: '10px' }}>
              Pause Music
            </button>
          )}
                    {/* DinoDoge Image Clickable for Playing Music */}
                    <img 
            src={DinoDoge} 
            alt="Dino Doge" 
            className="dino-doge-image"
            onClick={toggleAudio}
            style={{ cursor: 'pointer' }} // Make the cursor indicate clickable
          />


        </div>
      </div>
      <div className="green-container">
        <div className="contract-section">
          <Contract/>
          <TradeHeal />
        </div>
      </div>
      <Socials />
    </div>
  );
}

export default App;
