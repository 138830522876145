import React, { useState } from 'react';
import CopyIcon from './copy.png';
function Contract() {

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText("GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb")
      .then(() => {
        setCopySuccess('Copied to clipboard');
        setTimeout(() => {
          setCopySuccess('');
        }, 2000); // Message disappears after 2 seconds
      })
      .catch(err => console.error('Something went wrong', err));
  };

  return (
    <div className="contract-info">
      <h3>Contract</h3>
      <p>
        <a href="https://solscan.io/token/GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb" target="_blank" rel="noopener noreferrer">
          GaKwGHbdQut6nNtyKfU9YfD7emAASg5RJ4kMKmrA4Utb
        </a>
        <button className="copy-button" onClick={copyToClipboard}>
          <img src={CopyIcon} alt="Copy" className="copy-icon"/>
        </button>
        <span className={`copy-message ${copySuccess ? 'visible' : ''}`}>{copySuccess}</span>
      </p>
      <p>Supply: 69,420,888,888</p>
      <p>69 represents fitness</p>
      <p>420 brings peace and relaxation</p>
      <p>8s represent wishes of bountiful fortune</p>
      <p>
        LP's are burned. See the transactions here: 
        <a href="https://solscan.io/tx/4RUSE5FfZFFHhRpDYhyBpNj4adnnNWaRbdY1f7KfT7JikwZ4Yq8DFFL27zGdqWTtof8khrxDHWZh9ZbcSzWy5jcs" target="_blank" rel="noopener noreferrer"> Solana LP</a> and 
        <a href="https://solscan.io/tx/aurXhZ1hny8bptRw7D6a7kxTqYfjfGVkWA2r99Q9pfi9noiFQFNKck5xaCj1CXvxungQtYE7VCF9nLhc6eB1Tkh" target="_blank" rel="noopener noreferrer"> USDC LP</a>.
      </p>
    </div>
  );
  
}

export default Contract;
