import React from 'react';
import TelegramIcon from './telegram.svg'; // Import the SVG
import XIcon from './x-logo.svg';
function Socials() {
  return (
    <div className="socials-section">
      <a href="https://t.me/healyourwallet" target="_blank" rel="noopener noreferrer" className="telegram-link">
        <img src={TelegramIcon} alt="Telegram" />
      </a>
      <a href="https://twitter.com/HealYourWallet" target="_blank" rel="noopener noreferrer" className="x-link">
        <img src={XIcon} alt="X" />
      </a>
    </div>
  );
}

export default Socials;
